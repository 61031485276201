import styled from 'styled-components';
import breakpoint from '../../theme/breakpoint';

const Wrap = styled.div`
	padding: 40px 25px;
	max-width: 1040px;
	width: 100%;
	margin: 0 auto;

	${breakpoint('medium', `
		padding: 50px 25px;
	`)}
`;

export default Wrap;
