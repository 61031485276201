import React from 'react';
import {oneOf, number} from 'prop-types';
import small from './small.svg';
import large from './large.svg';

const Logo = ({type, width, height}) => (
	<img
		src={type === 'small' ? small : large}
		width={width}
		height={height}
		alt="Cambridge Dining Co"
	/>
);

Logo.propTypes = {
	type: oneOf(['large', 'small']),
	width: number,
	height: number
};

Logo.defaultProps = {
	type: 'small',
	width: 122,
	height: 117
};

export default Logo;
