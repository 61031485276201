import React from 'react';
import {string} from 'prop-types';
import {Head} from 'react-static';

const Meta = ({title}) => (
	<Head>
		<meta name="title" content={title ? title : 'Cambridge Dining Company'}/>
		<meta name="description" content="With a background in restaurants Cambridge Dining Co. have a reputation for fine British cuisine, great service and an eye for every detail. We apply our passions to your individual event."/>
		<meta itemProp="name" content="Cambridge Dining Company"/>
		<meta itemProp="url" content="http://www.cambridgedining.co.uk/"/>
		<meta itemProp="image" content="/logo.png"/>
		<meta itemProp="thumbnailUrl" content="/logo.png"/>
		<link rel="image_src" href="/logo.png"/>

		<meta property="og:site_name" content="Cambridge Dining Company"/>
		<meta property="og:title" content="Cambridge Dining Company"/>
		<meta property="og:url" content="http://www.cambridgedining.co.uk/"/>
		<meta property="og:type" content="website"/>
		<meta property="og:description" content="With a background in restaurants Cambridge Dining Co. have a reputation for fine British cuisine, great service and an eye for every detail. We apply our passions to your individual event."/>
		<meta property="og:image" content="/logo.png"/>
		<meta property="og:image:width" content="300"/>
		<meta property="og:image:height" content="300"/>

		<meta name="twitter:title" content="Cambridge Dining Company"/>
		<meta name="twitter:image" content="/logo.png"/>
		<meta name="twitter:url" content="http://www.cambridgedining.co.uk/"/>
		<meta name="twitter:card" content="summary"/>
		<meta name="twitter:description" content="With a background in restaurants Cambridge Dining Co. have a reputation for fine British cuisine, great service and an eye for every detail. We apply our passions to your individual event."/>
	</Head>
);

Meta.propTypes = {
	title: string
};

Meta.defaultProps = {
	title: ''
};

export default Meta;
