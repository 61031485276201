import styled from 'styled-components';
import color from '../../theme/color';
import font from '../../theme/font';
import breakpoint from '../../theme/breakpoint';

const LogoContainer = styled.div`
	background: ${color.soapstone};
	padding: 20px 25px;
	position: relative;
	max-width: 1040px;
	margin: 0 auto;

	> img,
	a img {
		display: block;
		width: ${props => props.type === 'small' ? '64px' : '240px'};
		height: auto;
		margin: ${props => props.type === 'small' ? props.theme === 'events' ? '0 13px 10px 3px' : '0 13px 10px 13px' : '0 auto'};
	}

	h2 {
		color: ${color.hawaiianTan};
		font-family: ${font.gothic.family};
		font-size: 12px;
		font-weight: normal;
		letter-spacing: 1.5px;
		margin: 0;
		text-transform: uppercase;
    text-align: center;
	}

	${props => breakpoint('medium', `
		padding: ${props.type === 'small' ? '25px 0' : '15px 0'};

		h2 {
			font-size: 17px;
			text-align: center;
		}

		> img,
		a img {
			width: ${props.type === 'small' ? '100px' : '293px'};
			height: ${props.type === 'small' ? '96px' : '117px'};
			margin: ${props.type === 'small' ? '0 auto 20px auto' : '0 auto'};
		}
	`)}
`;

export default LogoContainer;
