import React from 'react';
import color from '../../theme/color';
import {colorType} from '../../types';

const TwitterIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height}  viewBox="0 0 4332 4334">
    <path fill={color[props.color]} d="M0,836.216527 L0,3431.78347 C0,3893.62971 374.370285,4268 836.216527,4268 L3431.78347,4268 C3893.62971,4268 4268,3893.62971 4268,3431.78347 L4268,836.216527 C4268,374.370285 3893.62971,0 3431.78347,0 L836.216527,0 C374.370285,2.48065957e-14 0,374.370285 0,836.216527 Z M3265.76908,1553.67578 C3266.99798,1578.70084 3267.55658,1603.8376 3267.55658,1629.19781 C3267.55658,2400.84077 2680.24993,3290.57069 1606.1837,3290.57069 C1276.27757,3290.57069 969.497265,3193.82206 710.979557,3028.25453 C756.784336,3033.50533 803.25943,3036.18658 850.293118,3036.18658 C1124.11633,3036.18658 1375.59574,2942.90124 1575.46098,2786.38295 C1320.07141,2781.46732 1104.34207,2612.65995 1030.16067,2380.73136 C1065.79902,2387.43449 1102.21941,2391.34466 1139.98042,2391.34466 C1193.27037,2391.34466 1244.88454,2383.97121 1293.70573,2370.78837 C1026.92082,2317.16326 825.379787,2081.10107 825.379787,1798.22862 C825.379787,1798.22862 825.379787,1793.31299 825.379787,1790.85517 C904.253383,1834.64901 994.187158,1861.01468 1089.93032,1863.91938 C933.188598,1759.2387 830.183703,1580.48834 830.183703,1377.94184 C830.183703,1270.91506 859.007198,1170.70314 909.280737,1084.34437 C1197.18054,1437.71149 1627.63375,1670.19868 2112.82925,1694.55341 C2102.88627,1651.76504 2097.74719,1607.30089 2097.74719,1561.49611 C2097.74719,1238.96343 2359.16959,977.65275 2681.81399,977.65275 C2849.61589,977.65275 3001.44198,1048.5943 3107.91016,1161.98906 C3240.85574,1135.84682 3365.86928,1087.36078 3478.81716,1020.3294 C3435.13504,1156.73827 3342.63173,1271.1385 3222.08696,1343.30895 C3340.17391,1329.23236 3452.78664,1297.95105 3557.3556,1251.47596 C3478.81716,1368.44572 3379.83415,1471.22718 3265.76908,1553.67578 Z" transform="translate(31 29)"/>
  </svg>
);

TwitterIcon.defaultProps = {
  color: colorType
};

TwitterIcon.defaultProps = {
  color: 'white'
};

export default TwitterIcon;



