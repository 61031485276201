import React from 'react';
import {object, number, bool} from 'prop-types';
import Container from './container';
import FacebookIcon from './facebook';
import TwitterIcon from './twitter';
import PinterestIcon from './pinterest';
import InstagramIcon from './instagram';

const SocialLinks = ({facebook, twitter, pinterest, instagram, size, right}) => (
	<Container right={right}>
		{facebook.url && (
			<li key="facebook">
				<a href={facebook.url} target="_blank" rel="noopener noreferrer">
					<FacebookIcon width={size} height={size}/>
				</a>
			</li>
		)}
		{twitter.url && (
			<li key="twitter">
				<a href={twitter.url} target="_blank" rel="noopener noreferrer">
					<TwitterIcon width={size} height={size} />
				</a>
			</li>
		)}
		{pinterest.url && (
			<li key="pinterest">
				<a href={pinterest.url} target="_blank" rel="noopener noreferrer">
					<PinterestIcon width={size} height={size}/>
				</a>
			</li>
		)}
		{instagram.url && (
			<li key="instagram">
				<a href={instagram.url} target="_blank" rel="noopener noreferrer">
					<InstagramIcon width={size} height={size}/>
				</a>
			</li>
		)}
	</Container>
);

SocialLinks.propTypes = {
	facebook: object,
	twitter: object,
	pinterest: object,
	instagram: object,
	size: number,
	right: bool
};

SocialLinks.defaultProps = {
	facebook: {},
	twitter: {},
	pinterest: {},
	instagram: {},
	size: 38,
	right: true
};

export default SocialLinks;
