import React from 'react';
import color from '../../theme/color';
import {colorType} from '../../types';

const FacebookIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 4332 4334">
    <path fill={color[props.color]} d="M3407.40797,2179.74173 L2971.17344,2179.74173 L2971.17344,3735.36685 L2325.02071,3735.36685 L2325.02071,2179.74173 L2017.50008,2179.74173 L2017.50008,1630.4597 L2325.02071,1630.4597 L2325.02071,1274.83048 C2325.02071,1020.63309 2445.76018,622.656352 2977.12618,622.656352 L3455.92839,624.116611 L3455.92839,1157.89743 L3108.31102,1157.89743 C3051.81618,1157.89743 2971.17344,1185.97933 2971.17344,1307.29316 L2971.17344,1630.4597 L3463.90281,1630.4597 L3407.40797,2179.74173 Z M711.492076,4268.13672 L3557.57418,4268.13672 C3950.23018,4268.13672 4268.53313,3949.80026 4268.53313,3557.10292 L4268.53313,711.170511 C4268.53313,318.473174 3950.23018,0.136717949 3557.57418,0.136717949 L711.492076,0.136717949 C318.836076,0.136717949 0.533128205,318.473174 0.533128205,711.170511 L0.533128205,3557.10292 C0.533128205,3949.80026 318.836076,4268.13672 711.492076,4268.13672 Z" transform="translate(27 26)"/>
  </svg>
);

FacebookIcon.defaultProps = {
  color: colorType
};

FacebookIcon.defaultProps = {
  color: 'white'
};

export default FacebookIcon;