import {oneOf, shape, string, number, func} from 'prop-types';
import color from './theme/color';

const colorType = oneOf(Object.keys(color));

const imageType = shape({
	url: string.isRequired,
	alt: string,
	dimensions: shape({
		width: number,
		height: number
	})
});

const buttonType = shape({
	text: string.isRequired,
	url: string,
	onClick: func
});

export {
	colorType,
	imageType,
	buttonType
};
