import styled from 'styled-components';
import breakpoint from '../../theme/breakpoint';

const Container = styled.ul`
	line-height: 0;

	li {
		display: inline-block;
		margin-right: 5px;
	}

	a {
		display: block;
    line-height: 0;
	}

	li:last-child {
		margin-right: 0;
	}

	${props => breakpoint('medium', `
		text-align: ${props.right ? 'right' : 'left'};
	`)}
`;

export default Container;
