const formatPhone = number => {
	if (!number) {
		return false;
	}

	number = number.replace(/ /g, '');
	number = number.replace('+44', '');
	number = number.replace('(0)', '0');
	return number;
};

export default formatPhone;
