import React from 'react';
import color from '../../theme/color';
import {colorType} from '../../types';

const PinterestIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height}  viewBox="0 0 4334 4340">
    <path fill={color[props.color]} fill-rule="evenodd" d="M700,0 L3568,0 C3953,0 4268,315 4268,700 L4268,3568 C4268,3953 3953,4268 3568,4268 L700,4268 C315,4268 0,3953 0,3568 L0,700 C0,315 315,0 700,0 Z M1847,2972 C1911,2824 1962,2552 1986,2512 C2037,2550 2048,2580 2111,2613 C2165,2642 2225,2657 2292,2661 C2424,2669 2561,2630 2655,2573 C3049,2339 3188,1652 2918,1299 C2901,1277 2885,1258 2868,1237 C2834,1195 2741,1107 2688,1075 C2592,1016 2477,974 2346,954 C2078,914 1793,983 1609,1103 C1554,1139 1465,1216 1430,1259 C1340,1367 1261,1472 1227,1638 C1168,1925 1267,2189 1443,2297 C1507,2336 1548,2347 1569,2263 C1592,2174 1612,2151 1560,2083 C1484,1984 1466,1860 1490,1716 C1527,1500 1692,1290 1904,1221 C2183,1130 2451,1193 2600,1356 C2813,1589 2719,2164 2481,2357 C2393,2428 2243,2466 2136,2379 C1956,2233 2216,1857 2193,1652 C2162,1373 1786,1433 1747,1765 C1739,1834 1744,1907 1765,1968 C1801,2072 1793,2038 1764,2147 C1732,2266 1705,2393 1678,2515 C1651,2638 1613,2761 1599,2889 C1589,2978 1582,3253 1606,3326 C1681,3317 1814,3048 1847,2972 Z" transform="translate(31 31)"/>
  </svg>
);

PinterestIcon.defaultProps = {
  color: colorType
};

PinterestIcon.defaultProps = {
  color: 'white'
};

export default PinterestIcon;
